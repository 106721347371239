<template>
  <div class="user-api-switch">
    <span :class="{ 'opacity-0': selectedValue }">{{ getLabel('eu') }}</span>
    <ui-switcher
      v-model="selectedValue"
      type="solid"
    />
    <span :class="{ 'opacity-0': !selectedValue }">{{ getLabel('uae') }}</span>
  </div>
</template>

<script>
export default {
  name: 'UserApiSwitch',

  computed: {
    selectedValue: {
      get() {
        return this.$apiSwitch.selected?.name === 'uae';
      },
      set(value) {
        this.update(value ? 'uae' : 'eu');
      },
    },
  },

  methods: {
    getLabel(name) {
      return this.$apiSwitch.items.find((it) => it.name === name)?.label;
    },
    update(item) {
      this.$apiSwitch.selected = item;

      if (this.$route.name === 'location-view') {
        window.location = this.$router.resolve({ name: 'locations' })?.href;
      } else {
        [window.location] = window.location.href.split('?');
      }
    },
  },
};
</script>
